import React, {useEffect, useState} from "react";
import {Link} from "gatsby";

const BannerItem = ({modifierClass, columns, imgSrc, imgWidth, imgHeight, title, description, isHome, link, categoryLink, category, rockAndRestore, rockYourLife, imgClass}) => {

    const [categoryText, setCategoryText] = useState('')

    useEffect(() => {

        if(category === 'supplements') {
            setCategoryText('All Supplements')
        }
        if(category === 'meal plans') {
            setCategoryText('Shop Meal Plans')
        }
        if(category === 'workout plans') {
            setCategoryText('Shop Workouts')
        }
        if(category === 'rock your life') {
            setCategoryText('Shop Rock Your Life')
        }

    },[category])

    return (

        <>
            {rockAndRestore ? (
                <div className={`banner ${modifierClass ? modifierClass : ''}`}>
                    <div className="container">
                        <div className="row gutters-20">
                            <div className="col-3 txt--center d-none d-md-block">
                                <img src="/images/banners/rr-bottle.png" className="rr-bottle" alt="Rock and Restore." width="192" height="341"/>
                            </div>
                            <div className={`${columns ? columns : 'col-12 col-md-7 col-lg-6'}`}>
                                {imgSrc.length ?
                                    <img
                                        src={imgSrc}
                                        alt={imgSrc}
                                        width={imgWidth}
                                        height={imgHeight}
                                        className="mx--auto d-block d-md-none mb--20" style={{ opacity: imgSrc.length  }}/>
                                    :
                                    <div className={`banner__dummy-box--${imgHeight}`}/>
                                }

                                <h2 className="banner__title mb--40 txt--uppercase" dangerouslySetInnerHTML={{ __html: title }}/>
                                <p className="banner__description">{description}</p>

                                <div className="banner__buttons">
                                    <Link to={link} className="btn">learn more</Link>
                                    {isHome &&
                                    <Link to={categoryLink} className="btn">{categoryText}</Link>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            ) : rockYourLife ? (
                <div className={`banner ${modifierClass ? modifierClass : ''}`}>
                    <div className="container">
                        <div className="row">
                            <div className={`${columns ? columns : 'col-12 col-md-7 col-lg-6'}`}>
                                {imgSrc.length ?
                                    <img
                                        src={imgSrc}
                                        alt={imgSrc}
                                        width={imgWidth}
                                        height={imgHeight}
                                        className="mx--auto d-block d-md-none mb--20" style={{ opacity: imgSrc.length  }}/>
                                    :
                                    <div className={`banner__dummy-box--${imgHeight}`}/>
                                }

                                    <h2 className="banner__title mb--40 txt--uppercase" dangerouslySetInnerHTML={{ __html: title }}/>


                                <p className="banner__description">{description}</p>

                                <div className="banner__buttons">
                                    {isHome &&
                                    <a href={categoryLink} className="btn" target="_blank" rel="nooperner noreferrer">{categoryText}</a>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            ) : (
                    <div className={`banner ${modifierClass ? modifierClass : ''}`}>
                        <div className="container">
                            <div className="row">
                                <div className={`${columns ? columns : 'col-12 col-md-7 col-lg-6'}`}>
                                    {imgSrc.length ?
                                        <img
                                            src={imgSrc}
                                            alt={imgSrc}
                                            width={imgWidth}
                                            height={imgHeight}
                                            className="mx--auto d-block d-md-none mb--20" style={{ opacity: imgSrc.length  }}/>
                                        :
                                        <div className={`banner__dummy-box--${imgHeight}`}/>
                                    }

                                    <h2 className="banner__title mb--40 txt--uppercase" dangerouslySetInnerHTML={{ __html: title }}/>
                                    <p className="banner__description">{description}</p>

                                    <div className="banner__buttons">
                                        <Link to={link} className="btn">learn more</Link>
                                        {isHome &&
                                        <Link to={categoryLink} className="btn">{categoryText}</Link>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )

            }


        </>

    )
}
export default BannerItem
