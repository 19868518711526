import {Link} from "gatsby";
import React, {useEffect, useState} from "react";

const NavCardMenu = () => {

    const [country, setCountry] = useState('US')
    useEffect(()=>{
        setCountry(window.localStorage.getItem("country"))
    },[]);

    return (
        <section className="pt--30 pb--0 px--10p">
            <div className="row gutters-responsive justify-content-center">
                <div className="col-6 col-xl-3 margin-responsive">
                    <div className="nav-card">
                        <div className="nav-card__img nav-card__img--sm">
                            <img src="/images/cards/card-workout.png" alt="" width="297" height="355" className="d-none d-md-block"/>
                            <img src="/images/cards/card-workout-mob.png" alt="" width="241" height="224" className="d-block d-md-none"/>
                        </div>
                        <div className="nav-card__content">
                            <h2 className="nav-card__title">workout plans</h2>
                            <Link to="/category/workout-plans" className="link">Shop Now</Link>
                        </div>
                    </div>
                </div>

                <div className="col-6 col-xl-3 margin-responsive">
                    <div className="nav-card">
                        <div className="nav-card__img">
                            <img src="/images/cards/card-ryl.png" alt="" width="318" height="380" className="d-none d-md-block"/>
                            <img src="/images/cards/card-ryl-mob.png" alt="" width="385" height="215" className="d-block d-md-none"/>
                        </div>
                        <div className="nav-card__content">
                            <h2 className="nav-card__title">rock your life</h2>
                            <a href="https://shop.thebettyrocker.com/rock-your-life/" target="_blank" rel="noopener" className="link">Shop Now</a>
                        </div>
                    </div>
                </div>

                <div className="col-6 col-xl-3 margin-responsive">
                    <div className="nav-card">
                        <div className="nav-card__img">
                            <img src="/images/cards/card-meal-plans.png" alt="" width="318" height="380" className="d-none d-md-block"/>
                            <img src="/images/cards/card-meal-plans-mob.png" alt="" width="385" height="215" className="d-block d-md-none"/>
                        </div>
                        <div className="nav-card__content">
                            <h2 className="nav-card__title">meal plans</h2>
                            <Link to="/category/meal-plans" className="link">Shop Now</Link>
                        </div>
                    </div>
                </div>

                {country === "US" &&
                <div className="col-6 col-xl-3 margin-responsive">
                    <div className="nav-card">
                        <div className="nav-card__img nav-card__img--sm">
                            <img src="/images/cards/card-supplements.png" alt="" width="303" height="334" className="d-none d-md-block"/>
                            <img src="/images/cards/card-supplements-mob.png" alt="" width="276" height="211" className="d-block d-md-none"/>
                        </div>
                        <div className="nav-card__content">
                            <h2 className="nav-card__title">supplements</h2>
                            <Link to="/category/supplements" className="link">Shop Now</Link>
                        </div>
                    </div>
                </div>
                }

                {country !== "US" &&
                <div className="col-6 d-xl-none margin-responsive">
                    <div className="nav-card nav-card--bg d-flex align-items-center justify-content-center">
                        <h2 className="nav-card__extra txt--white txt--uppercase">stop, drop, and <br/>
                            <span className="txt--lg txt--pink">#bettyrock</span><br/> <span className="txt--xl">your life</span>
                        </h2>
                    </div>
                </div>
                }
            </div>
        </section>
    )
}

export default NavCardMenu
