import Banners from "./Banners";
import React from "react";

const HeroCarousel = ({type}) => {
    return (
        <section className="home-hero">
            <Banners type={type}/>
        </section>
    )
}
export default HeroCarousel;
