import {Link} from "gatsby";
import React, {useEffect, useState} from "react";
import Slider from "../components/Slider"

const Testimonial = ({type}) => {

    const [country, setCountry] = useState('US')
    useEffect(()=>{
        setCountry(window.localStorage.getItem("country"));
    },[])

    return (
        <section className="testimonial-carousel-wrapper">
            <div className="testimonial-carousel-content">
                <h2 className="h2--sm txt--white txt--uppercase mb--40">what our rockstars <span className="d-inline-block">say about us</span></h2>

                <div className="testimonial-carousel">
                    <div className="testimonial-carousel__item">

                        { type === 'home' && country === 'US' &&
                        <Slider
                            modifierClass="testimonial-carousel"
                            showIndicators={true}
                            autoPlay={true}
                            showThumbs={false}
                        >
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-12 col-md-7 order-2 order-md-1">
                                        <h4 className="txt--white mb--20">90 Day Challenge</h4>
                                        <p>“Thank you Betty Rocker! The 90 day challenge was a complete success! I’ve seen so many changes since I started.</p>
                                        <p>Since completing the program, we continue to follow your daily meal structure...”</p>
                                        <p className="testimonial__author"><strong className="fw--bold">- Cortney F.</strong></p>

                                        <Link to="/products/90-day-challenge/" className="btn btn--lmd btn--outline-white">Shop This Product <i className="far fa-long-arrow-right ml--10"/></Link>
                                    </div>
                                    <div className="col-12 col-md-5 order-1 order-md-2 mb--md-20 txt--center">
                                        <img src="/images/90dch/testimonial-1.jpg" alt="Testimonial of Cortney F." width={432} height={432} className="testimonial-carousel__img"/>
                                    </div>
                                </div>
                            </div>

                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-12 col-md-7 order-2 order-md-1">
                                        <h4 className="txt--white mb--20">90 Day Challenge</h4>
                                        <p>“I finished the 90 day challenge today! I feel like such a badass! I looked at B.B. (before Betty) and A.B. (after Betty) photos today, and I”m reminded how much I’ve accomplished so far.”</p>
                                        <p className="testimonial__author"><strong className="fw--bold">- Jen Y.</strong></p>

                                        <Link to="/products/90-day-challenge/" className="btn btn--lmd btn--outline-white">Shop This Product <i className="far fa-long-arrow-right ml--10"/></Link>
                                    </div>
                                    <div className="col-12 col-md-5 order-1 order-md-2 mb--md-20 txt--center">
                                        <img src="/images/hwd/testimonial-2.jpg" alt="Testimonial of Jen Y." width={432} height={432} className="testimonial-carousel__img"/>
                                    </div>
                                </div>
                            </div>

                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-12 col-md-7 order-2 order-md-1">
                                        <h4 className="txt--white mb--20">Body Fuel System</h4>
                                        <p>"I’ve always hated cooking with a passion!</p>
                                        <p>Not to mention never had any good ideas for meals.</p>
                                        <p>So when it came to wanting to cut fat and tone up I was lost on where to start..."</p>
                                        <p className="testimonial__author"><strong className="fw--bold">- Jenna T.</strong></p>

                                        <Link to="/products/body-fuel-system/" className="btn btn--lmd btn--outline-white">Shop This Product <i className="far fa-long-arrow-right ml--10"/></Link>
                                    </div>
                                    <div className="col-12 col-md-5 order-1 order-md-2 mb--md-20 txt--center">
                                        <img src="/images/bfs/testimonial-6.jpg" alt="Testimonial of Jenna T." width={400} height={400} className="testimonial-carousel__img"/>
                                    </div>
                                </div>
                            </div>

                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-12 col-md-7 order-2 order-md-1">
                                        <h4 className="txt--white mb--20">Body Fuel System</h4>
                                        <p>"Still a work in progress, but I’ve been following the Body Fuel System and the workouts.</p>
                                        <p>Thank you for your help, to a new healthier me.</p>
                                        <p>The next phase is all up to me. I got this!!"</p>
                                        <p className="testimonial__author"><strong className="fw--bold">- Veronica M.</strong></p>

                                        <Link to="/products/body-fuel-system/" className="btn btn--lmd btn--outline-white">Shop This Product <i className="far fa-long-arrow-right ml--10"/></Link>
                                    </div>
                                    <div className="col-12 col-md-5 order-1 order-md-2 mb--md-20 txt--center">
                                        <img src="/images/bfs/testimonial-17.jpg" alt="Testimonial of Veronica M." width={432} height={432} className="testimonial-carousel__img"/>
                                    </div>
                                </div>
                            </div>

                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-12 col-md-5 order-2 order-md-1">
                                        <h4 className="txt--white mb--20">I <i className="fas fa-heart"/> Vanilla Protein</h4>
                                        <p>"I've been loving the I <i className="fas fa-heart"/> Vanilla Protein protein powder! I use vanilla protein almost every day, and I'm always on the lookout for one that is gluten free, dairy..."</p>
                                        <p className="testimonial__author"><strong className="fw--bold">- Roz V.</strong></p>

                                        <Link to="/products/vanilla-protein/" className="btn btn--lmd btn--outline-white">Shop This Product <i className="far fa-long-arrow-right ml--10"/></Link>
                                    </div>
                                    <div className="col-12 col-md-7 order-1 order-md-2 mb--md-20">
                                        <div className="video bg--white video--16-9 border-5 border-white radius-5">
                                            <iframe title="Testimonial Video." src="https://player.vimeo.com/video/492151707" frameBorder="0"/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-12 col-md-5 order-2 order-md-1">
                                        <h4 className="txt--white mb--20">Berry Green Protein</h4>
                                        <p>"It is a game changer for when I'm traveling...I've tried other powders that have greens in them and I just don't like the taste, and they're hard to mix with just water - and that is not the case..."</p>
                                        <p className="testimonial__author"><strong className="fw--bold">- Angela D.</strong></p>

                                        <Link to="/products/berry-green-protein/" className="btn btn--lmd btn--outline-white">Shop This Product <i className="far fa-long-arrow-right ml--10"></i></Link>
                                    </div>
                                    <div className="col-12 col-md-7 order-1 order-md-2 mb--md-20">
                                        <div className="video bg--white video--16-9 border-5 border-white radius-5">
                                            <iframe title="Testimonial Video." src="https://player.vimeo.com/video/359377752" frameBorder="0"/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </Slider>
                        }

                        { type === 'home' && country !== 'US' &&
                        <Slider
                            modifierClass="testimonial-carousel"
                            showIndicators={true}
                            autoPlay={true}
                            showThumbs={false}
                        >
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-12 col-md-7 order-2 order-md-1">
                                        <h4 className="txt--white mb--20">90 Day Challenge</h4>
                                        <p>“Thank you Betty Rocker! The 90 day challenge was a complete success! I’ve seen so many changes since I started.</p>
                                        <p>Since completing the program, we continue to follow your daily meal structure...”</p>
                                        <p className="testimonial__author"><strong className="fw--bold">- Cortney F.</strong></p>

                                        <Link to="/products/90-day-challenge/" className="btn btn--lmd btn--outline-white">Shop This Product <i className="far fa-long-arrow-right ml--10"/></Link>
                                    </div>
                                    <div className="col-12 col-md-5 order-1 order-md-2 mb--md-20 txt--center">
                                        <img src="/images/90dch/testimonial-1.jpg" alt="Testimonial of Cortney F." width={432} height={432} className="testimonial-carousel__img"/>
                                    </div>
                                </div>
                            </div>

                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-12 col-md-7 order-2 order-md-1">
                                        <h4 className="txt--white mb--20">90 Day Challenge</h4>
                                        <p>“I finished the 90 day challenge today! I feel like such a badass! I looked at B.B. (before Betty) and A.B. (after Betty) photos today, and I”m reminded how much I’ve accomplished so far.”</p>
                                        <p className="testimonial__author"><strong className="fw--bold">- Jen Y.</strong></p>

                                        <Link to="/products/90-day-challenge/" className="btn btn--lmd btn--outline-white">Shop This Product <i className="far fa-long-arrow-right ml--10"/></Link>
                                    </div>
                                    <div className="col-12 col-md-5 order-1 order-md-2 mb--md-20 txt--center">
                                        <img src="/images/hwd/testimonial-2.jpg" alt="Testimonial of Jen Y." width={432} height={432} className="testimonial-carousel__img"/>
                                    </div>
                                </div>
                            </div>

                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-12 col-md-7 order-2 order-md-1">
                                        <h4 className="txt--white mb--20">Body Fuel System</h4>
                                        <p>"I’ve always hated cooking with a passion!</p>
                                        <p>Not to mention never had any good ideas for meals.</p>
                                        <p>So when it came to wanting to cut fat and tone up I was lost on where to start..."</p>
                                        <p className="testimonial__author"><strong className="fw--bold">- Jenna T.</strong></p>

                                        <Link to="/products/body-fuel-system/" className="btn btn--lmd btn--outline-white">Shop This Product <i className="far fa-long-arrow-right ml--10"/></Link>
                                    </div>
                                    <div className="col-12 col-md-5 order-1 order-md-2 mb--md-20 txt--center">
                                        <img src="/images/bfs/testimonial-6.jpg" alt="Testimonial of Jenna T." width={400} height={400} className="testimonial-carousel__img"/>
                                    </div>
                                </div>
                            </div>

                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-12 col-md-7 order-2 order-md-1">
                                        <h4 className="txt--white mb--20">Body Fuel System</h4>
                                        <p>"Still a work in progress, but I’ve been following the Body Fuel System and the workouts.</p>
                                        <p>Thank you for your help, to a new healthier me...</p>
                                        <p className="testimonial__author"><strong className="fw--bold">- Veronica M.</strong></p>

                                        <Link to="/products/body-fuel-system/" className="btn btn--lmd btn--outline-white">Shop This Product <i className="far fa-long-arrow-right ml--10"/></Link>
                                    </div>
                                    <div className="col-12 col-md-5 order-1 order-md-2 mb--md-20 txt--center">
                                        <img src="/images/bfs/testimonial-17.jpg" alt="Testimonial of Veronica M." width={432} height={432} className="testimonial-carousel__img"/>
                                    </div>
                                </div>
                            </div>
                        </Slider>
                        }
                        { type === 'supplements' &&
                            <Slider
                                modifierClass="testimonial-carousel"
                                showIndicators={true}
                                autoPlay={true}
                                showThumbs={false}
                            >
                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="col-12 col-md-5 order-2 order-md-1">
                                            <h4 className="txt--white mb--20">I <i className="fas fa-heart"/> Vanilla Protein</h4>
                                            <p>"I've been loving the I <i className="fas fa-heart"/> Vanilla Protein protein powder! I use vanilla protein almost every day, and I'm always on the lookout for one that is gluten free..."</p>
                                            <p className="testimonial__author"><strong className="fw--bold">- Roz V.</strong></p>

                                            <Link to="/products/vanilla-protein/" className="btn btn--lmd btn--outline-white">Shop This Product <i className="far fa-long-arrow-right ml--10"/></Link>
                                        </div>
                                        <div className="col-12 col-md-7 order-1 order-md-2 mb--md-20">
                                            <div className="video bg--white video--16-9 border-5 border-white radius-5">
                                                <iframe title="Testimonial Video." src="https://player.vimeo.com/video/492151707" frameBorder="0"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="col-12 col-md-5 order-2 order-md-1">
                                            <h4 className="txt--white mb--20">Berry Green Protein</h4>
                                            <p>"It is a game changer for when I'm traveling...I've tried other powders that have greens in them and I just don't like the taste, and they're hard to mix with just water - and that is not..."</p>
                                            <p className="testimonial__author"><strong className="fw--bold">- Angela D.</strong></p>

                                            <Link to="/products/berry-green-protein/" className="btn btn--lmd btn--outline-white">Shop This Product <i className="far fa-long-arrow-right ml--10"/></Link>
                                        </div>
                                        <div className="col-12 col-md-7 order-1 order-md-2 mb--md-20">
                                            <div className="video bg--white video--16-9 border-5 border-white radius-5">
                                                <iframe title="Testimonial Video." src="https://player.vimeo.com/video/359377752" frameBorder="0"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="col-12 col-md-5 order-2 order-md-1">
                                            <h4 className="txt--white mb--20">Full Body Collagen</h4>
                                            <p>"When it comes to Whole Betty, the message is: Fuel your body, fuel your mind - be strong - and because of that, you feel really good about how you look and who you are and how you feel..."</p>
                                            <p className="testimonial__author"><strong className="fw--bold">- Cara L.</strong></p>

                                            <Link to="/products/full-body-collagen/" className="btn btn--lmd btn--outline-white">Shop This Product <i className="far fa-long-arrow-right ml--10"/></Link>
                                        </div>
                                        <div className="col-12 col-md-7 order-1 order-md-2 mb--md-20">
                                            <div className="video bg--white video--16-9 border-5 border-white radius-5">
                                                <iframe title="Testimonial Video." src="https://player.vimeo.com/video/378670837" frameBorder="0"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="col-12 col-md-5 order-2 order-md-1">
                                            <h4 className="txt--white mb--20">SereniCalm</h4>
                                            <p>"The very first time I took it it was so helpful. The very first night I slept so much better - I woke up feeling so much less stressed the next day."</p>
                                            <p className="testimonial__author"><strong className="fw--bold">- Kristen B.</strong></p>

                                            <Link to="/products/serenicalm/" className="btn btn--lmd btn--outline-white">Shop This Product <i className="far fa-long-arrow-right ml--10"/></Link>
                                        </div>
                                        <div className="col-12 col-md-7 order-1 order-md-2 mb--md-20">
                                            <div className="video bg--white video--16-9 border-5 border-white radius-5">
                                                <iframe title="Testimonial Video." src="https://player.vimeo.com/video/456711536" frameBorder="0"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="col-12 col-md-5 order-2 order-md-1">
                                            <h4 className="txt--white mb--20">Whole Sleep</h4>
                                            <p>"I wake up feeling refreshed every morning - full of energy."</p>
                                            <p className="testimonial__author"><strong className="fw--bold">- Carrie Ann H.</strong></p>

                                            <Link to="/products/whole-sleep/" className="btn btn--lmd btn--outline-white">Shop This Product <i className="far fa-long-arrow-right ml--10"/></Link>
                                        </div>
                                        <div className="col-12 col-md-7 order-1 order-md-2 mb--md-20">
                                            <div className="video bg--white video--16-9 border-5 border-white radius-5">
                                                <iframe title="Testimonial Video." src="https://player.vimeo.com/video/396799261" frameBorder="0"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="col-12 col-md-5 order-2 order-md-1">
                                            <h4 className="txt--white mb--20">Rock and Restore</h4>
                                            <p>"Give your body those essential amino acids to help with muscle repair, and keep you rolling through the next day, and all the way through day 30 of your challenges..."</p>
                                            <p className="testimonial__author"><strong className="fw--bold">- Maria C.</strong></p>

                                            <Link to="/products/rock-and-restore/" className="btn btn--lmd btn--outline-white">Shop This Product <i className="far fa-long-arrow-right ml--10"/></Link>
                                        </div>
                                        <div className="col-12 col-md-7 order-1 order-md-2 mb--md-20">
                                            <div className="video bg--white video--16-9 border-5 border-white radius-5">
                                                <iframe title="Testimonial Video." src="https://player.vimeo.com/video/568923436" frameBorder="0"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Slider>
                        }
                        { type === 'meal plans' &&
                            <Slider
                                modifierClass="testimonial-carousel"
                                showIndicators={true}
                                autoPlay={true}
                                showThumbs={false}
                            >
                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="col-12 col-md-7 order-2 order-md-1">
                                            <h4 className="txt--white mb--20">Body Fuel System</h4>
                                            <p>"I’ve always hated cooking with a passion!</p>
                                            <p>Not to mention never had any good ideas for meals.</p>
                                            <p>So when it came to wanting to cut fat and tone up I was lost on where to start..."</p>
                                            <p className="testimonial__author"><strong className="fw--bold">- Jenna T.</strong></p>

                                            <Link to="/products/body-fuel-system/" className="btn btn--lmd btn--outline-white">Shop This Product <i className="far fa-long-arrow-right ml--10"/></Link>
                                        </div>
                                        <div className="col-12 col-md-5 order-1 order-md-2 mb--md-20 txt--center">
                                            <img src="/images/bfs/testimonial-6.jpg" alt="Testimonial of Jenna T." width={400} height={400} className="testimonial-carousel__img"/>
                                        </div>
                                    </div>
                                </div>

                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="col-12 col-md-7 order-2 order-md-1">
                                            <h4 className="txt--white mb--20">Body Fuel System</h4>
                                            <p>"Still a work in progress, but I’ve been following the Body Fuel System and the workouts.</p>
                                            <p>Thank you for your help, to a new healthier me..."</p>
                                            <p className="testimonial__author"><strong className="fw--bold">- Veronica M.</strong></p>

                                            <Link to="/products/body-fuel-system/" className="btn btn--lmd btn--outline-white">Shop This Product <i className="far fa-long-arrow-right ml--10"/></Link>
                                        </div>
                                        <div className="col-12 col-md-5 order-1 order-md-2 mb--md-20 txt--center">
                                            <img src="/images/bfs/testimonial-17.jpg" alt="Testimonial of Veronica M." width={432} height={432} className="testimonial-carousel__img"/>
                                        </div>
                                    </div>
                                </div>

                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="col-12 col-md-7 order-2 order-md-1">
                                            <h4 className="txt--white mb--20">30 Day Meal Challenge</h4>
                                            <p>"Hi! I just finished the 30 day challenge. This is my before after pictures. I share them so u can see that it really work. I didnt lost a lot of weight but u can see that..."</p>
                                            <p className="testimonial__author"><strong className="fw--bold">- Melina O.</strong></p>

                                            <Link to="/products/30-day-meal-plan/" className="btn btn--lmd btn--outline-white">Shop This Product <i className="far fa-long-arrow-right ml--10"/></Link>
                                        </div>
                                        <div className="col-12 col-md-5 order-1 order-md-2 mb--md-20 txt--center">
                                            <img src="/images/30dch/testimonial-1.jpg" alt="Testimonial of Melina O." width={432} height={432} className="testimonial-carousel__img"/>
                                        </div>
                                    </div>
                                </div>

                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="col-12 col-md-7 order-2 order-md-1">
                                            <h4 className="txt--white mb--20">30 Day Meal Challenge</h4>
                                            <p>"Your 30 day program popped up on my Facebook and I decided to give it a go. I really didn't expect to see much of a difference, so I was really excited when I put these two..."</p>
                                            <p className="testimonial__author"><strong className="fw--bold">- Leanne S.</strong></p>

                                            <Link to="/products/30-day-meal-plan/" className="btn btn--lmd btn--outline-white">Shop This Product <i className="far fa-long-arrow-right ml--10"/></Link>
                                        </div>
                                        <div className="col-12 col-md-5 order-1 order-md-2 mb--md-20 txt--center">
                                            <img src="/images/30dch/testimonial-3.jpg" alt="Testimonial of Leanne S." width={432} height={432} className="testimonial-carousel__img"/>
                                        </div>
                                    </div>
                                </div>

                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="col-12 col-md-7 order-2 order-md-1">
                                            <h4 className="txt--white mb--20">30 Day Meal Challenge</h4>
                                            <p>"There’s a big difference between being fit and being healthy, and I do plenty of fitness on a regular basis….the problem I had was that I was just not seeing the results I should have been for the amount of exercise I was doing..."</p>
                                            <p className="testimonial__author"><strong className="fw--bold">- Louise C.</strong></p>

                                            <Link to="/products/30-day-meal-plan/" className="btn btn--lmd btn--outline-white">Shop This Product <i className="far fa-long-arrow-right ml--10"/></Link>
                                        </div>
                                        <div className="col-12 col-md-5 order-1 order-md-2 mb--md-20 txt--center">
                                            <img src="/images/30dch/testimonial-13.jpg" alt="Testimonial of Louise C." width={432} height={432} className="testimonial-carousel__img"/>
                                        </div>
                                    </div>
                                </div>
                            </Slider>
                        }
                        { type === 'workout plans' &&
                            <Slider
                                modifierClass="testimonial-carousel"
                                showIndicators={true}
                                autoPlay={true}
                                showThumbs={false}
                                >
                                <div className="container">
                                    <div className="row align-items-center">
                                    <div className="col-12 col-md-7 order-2 order-md-1">
                                        <h4 className="txt--white mb--20">90 Day Challenge</h4>
                                        <p>“Thank you Betty Rocker! The 90 day challenge was a complete success! I’ve seen so many changes since I started.</p>
                                        <p>Since completing the program, we continue to follow your daily meal structure...”</p>
                                        <p className="testimonial__author"><strong className="fw--bold">- Cortney F.</strong></p>

                                        <Link to="/products/90-day-challenge/" className="btn btn--lmd btn--outline-white">Shop This Product <i className="far fa-long-arrow-right ml--10"/></Link>
                                    </div>
                                    <div className="col-12 col-md-5 order-1 order-md-2 mb--md-20 txt--center">
                                        <img src="/images/90dch/testimonial-1.jpg" alt="Testimonial of Cortney F." width={432} height={432} className="testimonial-carousel__img"/>
                                    </div>
                                </div>
                                </div>

                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="col-12 col-md-7 order-2 order-md-1">
                                            <h4 className="txt--white mb--20">90 Day Challenge</h4>
                                            <p>“I finished the 90 day challenge today! I feel like such a badass! I looked at B.B. (before Betty) and A.B. (after Betty) photos today, and I”m reminded how much I’ve accomplished so far.”</p>
                                            <p className="testimonial__author"><strong className="fw--bold">- Jen Y.</strong></p>

                                            <Link to="/products/90-day-challenge/" className="btn btn--lmd btn--outline-white">Shop This Product <i className="far fa-long-arrow-right ml--10"/></Link>
                                        </div>
                                        <div className="col-12 col-md-5 order-1 order-md-2 mb--md-20 txt--center">
                                            <img src="/images/90dch/testimonial-2.jpg" alt="Testimonial of Jen Y." width={432} height={432} className="testimonial-carousel__img"/>
                                        </div>
                                    </div>
                                </div>

                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="col-12 col-md-7 order-2 order-md-1">
                                            <h4 className="txt--white mb--20">90 Day Challenge</h4>
                                            <p>“My goals were to lose cm’s, feel good in my skin and commit to regular exercise. I have achieved all of it and feel great! I am stronger, I have more energy, I look forward to my workouts...”</p>
                                            <p className="testimonial__author"><strong className="fw--bold">- Dana C.</strong></p>

                                            <Link to="/products/90-day-challenge/" className="btn btn--lmd btn--outline-white">Shop This Product <i className="far fa-long-arrow-right ml--10"/></Link>
                                        </div>
                                        <div className="col-12 col-md-5 order-1 order-md-2 mb--md-20 txt--center">
                                            <img src="/images/90dch/testimonial-3.jpg" alt="Testimonial of Dana C." width={432} height={432} className="testimonial-carousel__img"/>
                                        </div>
                                    </div>
                                </div>

                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="col-12 col-md-7 order-2 order-md-1">
                                            <h4 className="txt--white mb--20">Home Workout Domination</h4>
                                            <p>“Here’s the first and last day before and after Home Workout Domination….who would have thought I would have gained muscle for the first time in my life at the age of 52!”</p>
                                            <p className="testimonial__author"><strong className="fw--bold">- Brenda C.</strong></p>

                                            <Link to="/products/home-workout-domination/" className="btn btn--lmd btn--outline-white">Shop This Product <i className="far fa-long-arrow-right ml--10"/></Link>
                                        </div>
                                        <div className="col-12 col-md-5 order-1 order-md-2 mb--md-20 txt--center">
                                            <img src="/images/hwd/testimonial-8.jpg" alt="Testimonial of Brenda C." width={432} height={432} className="testimonial-carousel__img"/>
                                        </div>
                                    </div>
                                </div>

                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="col-12 col-md-7 order-2 order-md-1">
                                            <h4 className="txt--white mb--20">Home Workout Domination</h4>
                                            <p>“16 weeks of Home Workout Domination (I did it twice). SAME WEIGHT – less inches! I’m moving on to Lioness, and I can’t wait to see my results!”</p>
                                            <p className="testimonial__author"><strong className="fw--bold">- Theresa V.</strong></p>

                                            <Link to="/products/home-workout-domination/" className="btn btn--lmd btn--outline-white">Shop This Product <i className="far fa-long-arrow-right ml--10"/></Link>
                                        </div>
                                        <div className="col-12 col-md-5 order-1 order-md-2 mb--md-20 txt--center">
                                            <img src="/images/hwd/testimonial-2.jpg" alt="Testimonial of Theresa V." width={432} height={432} className="testimonial-carousel__img"/>
                                        </div>
                                    </div>
                                </div>

                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="col-12 col-md-7 order-2 order-md-1">
                                            <h4 className="txt--white mb--20">30 Day Booty And Abs Challenge</h4>
                                            <p>“I’m constantly looking at my midsection in the mirror because I’m so happy to see fat literally melting off, and definition starting to form.”</p>
                                            <p className="testimonial__author"><strong className="fw--bold">- Chantele V.</strong></p>

                                            <Link to="/products/30-day-booty-abs-challenge/" className="btn btn--lmd btn--outline-white">Shop This Product <i className="far fa-long-arrow-right ml--10"/></Link>
                                        </div>
                                        <div className="col-12 col-md-5 order-1 order-md-2 mb--md-20 txt--center">
                                            <img src="/images/hot-abs/testimonial-2.jpg" alt="Testimonial of Chantele V." width={432} height={432} className="testimonial-carousel__img"/>
                                        </div>
                                    </div>
                                </div>

                            </Slider>
                        }

                        { type === 'programs' &&
                            <Slider
                                modifierClass="testimonial-carousel"
                                showIndicators={true}
                                autoPlay={true}
                                showThumbs={false}
                                >
                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="col-12 col-md-7 order-2 order-md-1">
                                            <h4 className="txt--white mb--20">90 Day Challenge</h4>
                                            <p>“Thank you Betty Rocker! The 90 day challenge was a complete success! I’ve seen so many changes since I started.</p>
                                            <p>Since completing the program, we continue to follow your daily meal structure...”</p>
                                            <p className="testimonial__author"><strong className="fw--bold">- Cortney F.</strong></p>

                                            <Link to="/products/90-day-challenge/" className="btn btn--lmd btn--outline-white">Shop This Product <i className="far fa-long-arrow-right ml--10"/></Link>
                                        </div>
                                        <div className="col-12 col-md-5 order-1 order-md-2 mb--md-20 txt--center">
                                            <img src="/images/90dch/testimonial-1.jpg" alt="Testimonial of Cortney F." width={432} height={432} className="testimonial-carousel__img"/>
                                        </div>
                                    </div>
                                </div>

                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="col-12 col-md-7 order-2 order-md-1">
                                            <h4 className="txt--white mb--20">90 Day Challenge</h4>
                                            <p>“I finished the 90 day challenge today! I feel like such a badass! I looked at B.B. (before Betty) and A.B. (after Betty) photos today, and...”</p>
                                            <p className="testimonial__author"><strong className="fw--bold">- Jen Y.</strong></p>

                                            <Link to="/products/90-day-challenge/" className="btn btn--lmd btn--outline-white">Shop This Product <i className="far fa-long-arrow-right ml--10"/></Link>
                                        </div>
                                        <div className="col-12 col-md-5 order-1 order-md-2 mb--md-20 txt--center">
                                            <img src="/images/hwd/testimonial-2.jpg" alt="Testimonial of Jen Y." width={432} height={432} className="testimonial-carousel__img"/>
                                        </div>
                                    </div>
                                </div>

                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="col-12 col-md-7 order-2 order-md-1">
                                            <h4 className="txt--white mb--20">90 Day Challenge</h4>
                                            <p>“My goals were to lose cm’s, feel good in my skin and commit to regular exercise. I have achieved all of it and feel great! I am stronger...”</p>
                                            <p className="testimonial__author"><strong className="fw--bold">- Dana C.</strong></p>

                                            <Link to="/products/90-day-challenge/" className="btn btn--lmd btn--outline-white">Shop This Product <i className="far fa-long-arrow-right ml--10"/></Link>
                                        </div>
                                        <div className="col-12 col-md-5 order-1 order-md-2 mb--md-20 txt--center">
                                            <img src="/images/hot-abs/testimonial-3.jpg" alt="Testimonial of Dana C." width={432} height={432} className="testimonial-carousel__img"/>
                                        </div>
                                    </div>
                                </div>

                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="col-12 col-md-7 order-2 order-md-1">
                                            <h4 className="txt--white mb--20">Home Workout Domination</h4>
                                            <p>“Here’s the first and last day before and after Home Workout Domination….who would have thought I would have gained muscle for the first time...”</p>
                                            <p className="testimonial__author"><strong className="fw--bold">- Brenda C.</strong></p>

                                            <Link to="/products/home-workout-domination/" className="btn btn--lmd btn--outline-white">Shop This Product <i className="far fa-long-arrow-right ml--10"/></Link>
                                        </div>
                                        <div className="col-12 col-md-5 order-1 order-md-2 mb--md-20 txt--center">
                                            <img src="/images/hwd/testimonial-8.jpg" alt="Testimonial of Brenda C." width={432} height={432} className="testimonial-carousel__img"/>
                                        </div>
                                    </div>
                                </div>

                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="col-12 col-md-7 order-2 order-md-1">
                                            <h4 className="txt--white mb--20">Body Fuel System</h4>
                                            <p>"I’ve always hated cooking with a passion!</p>
                                            <p>Not to mention never had any good ideas for meals.</p>
                                            <p>So when it came to wanting to cut fat and tone up I was lost on where to start..."</p>
                                            <p className="testimonial__author"><strong className="fw--bold">- Jenna T.</strong></p>

                                            <Link to="/products/body-fuel-system/" className="btn btn--lmd btn--outline-white">Shop This Product <i className="far fa-long-arrow-right ml--10"/></Link>
                                        </div>
                                        <div className="col-12 col-md-5 order-1 order-md-2 mb--md-20 txt--center">
                                            <img src="/images/bfs/testimonial-6.jpg" alt="Testimonial of Jenna T." width={400} height={400} className="testimonial-carousel__img"/>
                                        </div>
                                    </div>
                                </div>

                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="col-12 col-md-7 order-2 order-md-1">
                                            <h4 className="txt--white mb--20">Body Fuel System</h4>
                                            <p>"Still a work in progress, but I’ve been following the Body Fuel System and the workouts.</p>
                                            <p>Thank you for your help, to a new healthier me...</p>
                                            <p className="testimonial__author"><strong className="fw--bold">- Veronica M.</strong></p>

                                            <Link to="/products/body-fuel-system/" className="btn btn--lmd btn--outline-white">Shop This Product <i className="far fa-long-arrow-right ml--10"/></Link>
                                        </div>
                                        <div className="col-12 col-md-5 order-1 order-md-2 mb--md-20 txt--center">
                                            <img src="/images/bfs/testimonial-17.jpg" alt="Testimonial of Veronica M." width={432} height={432} className="testimonial-carousel__img"/>
                                        </div>
                                    </div>
                                </div>

                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="col-12 col-md-7 order-2 order-md-1">
                                            <h4 className="txt--white mb--20">30 Day Meal Challenge</h4>
                                            <p>"Hi! I just finished the 30 day challenge. This is my before after pictures. I share them so u can see that it really work...</p>
                                            <p className="testimonial__author"><strong className="fw--bold">- Melina O.</strong></p>

                                            <Link to="/products/30-day-meal-plan/" className="btn btn--lmd btn--outline-white">Shop This Product <i className="far fa-long-arrow-right ml--10"/></Link>
                                        </div>
                                        <div className="col-12 col-md-5 order-1 order-md-2 mb--md-20 txt--center">
                                            <img src="/images/30dch/testimonial-1.jpg" alt="Testimonial of Melina O." width={432} height={432} className="testimonial-carousel__img"/>
                                        </div>
                                    </div>
                                </div>
                            </Slider>
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Testimonial
