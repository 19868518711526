import React, {useEffect, useState} from "react";

import Slider from "./Slider"
import BannerItem from "./BannerItem";

const Banners = ({ type }) => {

    const [country, setCountry] = useState('US')

    useEffect(()=>{
        setCountry(window.localStorage.getItem("country"))
    },[])


    return (
        <>
            { type === 'homepage' && country === "US" &&
                <Slider
                    showIndicators={true}
                    autoPlay={true}
                    notSwipeable={false}
                    showThumbs={false}>
                    {/*banner Body Fuel System*/}
                    <BannerItem
                        modifierClass="bg-banner-bfs"
                        columns="col-12 col-md-7 col-lg-6"
                        imgSrc=""
                        imgWidth="767"
                        imgHeight="380"
                        title="<span class='txt--pink'>Body Fuel</span> System"
                        description="This easy eating system and 6-week meal plan is a complete blueprint to making food work like a team in your body so you can enjoy better energy, natural fat burning and support your active lifestyle."
                        isHome={true}
                        link="/products/body-fuel-system"
                        categoryLink="/category/meal-plans"
                        category="meal plans"
                    />
                    {/*banner 90 day home workout*/}
                    <BannerItem
                        modifierClass="bg-banner-90dch"
                        columns="col-12 col-md-8 col-lg-6 offset-lg-6 offset-md-4"
                        imgSrc=""
                        imgWidth="767"
                        imgHeight="400"
                        title="<span class='txt--pink'>90-day</span> home<br class='d-none d-lg-block'/> <span class='d-inline-block'>workout <span class='txt--pink'>challenge</span></span>"
                        description="#makefatcry in 15 minutes a Day! No Equipment, No Excuses, Just #stopdropandbettyrock"
                        isHome={true}
                        link="/products/90-day-challenge/"
                        categoryLink="/category/workout-plans/"
                        category="workout plans"
                    />
                    {/*banner Rock and Restore*/}
                    <BannerItem
                        modifierClass="bg-banner-rr2"
                        rockAndRestore={true}
                        columns="col-12 col-md-8 col-lg-6"
                        imgSrc=""
                        imgWidth="767"
                        imgHeight="340"
                        title="Rock and restore"
                        description="This great tasting fruit punch formula contains all 9 of the essential amino acids (including the BCAA’s) so you can rock your workouts, build lean muscle, recover faster, boost your immune system and improve cognitive function."
                        isHome={true}
                        link="/products/rock-and-restore/"
                        categoryLink="/category/supplements/"
                        category="supplements"
                    />

                    {/*banner Rock Your Life*/}
                    <BannerItem
                        modifierClass="bg-banner-ryl"
                        columns="col-12 col-md-8 col-lg-6"
                        rockYourLife={true}
                        imgSrc=""
                        imgWidth="767"
                        imgHeight="340"
                        title="Rock Your Life"
                        description="Join the online home  workout studio for 30 day challenges, fresh new workout classes and  support from Betty Rocker and the coaches inside our private women's  fitness community!"
                        isHome={true}
                        categoryLink="https://shop.thebettyrocker.com/rock-your-life/"
                        category="rock your life"
                    />

                    {/*banner Rock Your Life 2
                    <BannerItem
                        modifierClass="bg-banner-ryl2"
                        columns="col-12 col-md-8 col-lg-6"
                        rockYourLife={true}
                        imgSrc=""
                        imgWidth="767"
                        imgHeight="420"
                        title="Rock Your Life"
                        description="Join the online home  workout studio for 30 day challenges, fresh new workout classes and  support from Betty Rocker and the coaches inside our private women's  fitness community!"
                        isHome={true}
                        categoryLink="https://shop.thebettyrocker.com/rock-your-life/"
                        category="rock your life"
                    />
                    */}
            </Slider>
            }

            { type === 'homepage' && country !== "US" &&
            <Slider
                showIndicators={true}
                autoPlay={true}
                notSwipeable={false}
                showThumbs={false}>
                {/*banner Body Fuel System*/}
                <BannerItem
                    modifierClass="bg-banner-bfs"
                    columns="col-12 col-md-7 col-lg-6"
                    imgSrc=""
                    imgWidth="767"
                    imgHeight="380"
                    title="<span class='txt--pink'>Body Fuel</span> System"
                    description="This easy eating system and 6-week meal plan is a complete blueprint to making food work like a team in your body so you can enjoy better energy, natural fat burning and support your active lifestyle."
                    isHome={true}
                    link="/products/body-fuel-system"
                    categoryLink="/category/meal-plans"
                    category="meal plans"
                />
                {/*banner 90 day home workout*/}
                <BannerItem
                    modifierClass="bg-banner-90dch"
                    columns="col-12 col-md-8 col-lg-6 offset-lg-6 offset-md-4"
                    imgSrc=""
                    imgWidth="767"
                    imgHeight="400"
                    title="<span class='txt--pink'>90-day</span> home<br class='d-none d-lg-block'/> <span class='d-inline-block'>workout <span class='txt--pink'>challenge</span></span>"
                    description="#makefatcry in 15 minutes a Day! No Equipment, No Excuses, Just #stopdropandbettyrock"
                    isHome={true}
                    link="/products/90-day-challenge/"
                    categoryLink="/category/workout-plans/"
                    category="workout plans"
                />

                {/*banner Rock Your Life*/}
                <BannerItem
                    modifierClass="bg-banner-ryl"
                    columns="col-12 col-md-8 col-lg-6"
                    rockYourLife={true}
                    imgSrc=""
                    imgWidth="767"
                    imgHeight="340"
                    title="Rock Your Life"
                    description="Join the online home  workout studio for 30 day challenges, fresh new workout classes and  support from Betty Rocker and the coaches inside our private women's  fitness community!"
                    isHome={true}
                    categoryLink="https://shop.thebettyrocker.com/rock-your-life/"
                    category="rock your life"
                />

                {/*banner Rock Your Life 2
                    <BannerItem
                        modifierClass="bg-banner-ryl2"
                        columns="col-12 col-md-8 col-lg-6"
                        rockYourLife={true}
                        imgSrc=""
                        imgWidth="767"
                        imgHeight="420"
                        title="Rock Your Life"
                        description="Join the online home  workout studio for 30 day challenges, fresh new workout classes and  support from Betty Rocker and the coaches inside our private women's  fitness community!"
                        isHome={true}
                        categoryLink="https://shop.thebettyrocker.com/rock-your-life/"
                        category="rock your life"
                    />
                    */}
            </Slider>
            }

            {/*MEAL PLANS*/}
            { type === 'meal plans' &&
                <Slider
                    showIndicators={true}
                    autoPlay={true}
                    notSwipeable={false}
                    showThumbs={false}>
                    {/*banner Body Fuel System*/}
                    <BannerItem
                        modifierClass="bg-banner-bfs"
                        columns="col-12 col-md-7 col-lg-6"
                        imgSrc=""
                        imgWidth="767"
                        imgHeight="380"
                        title="<span class='txt--pink'>Body Fuel</span> System"
                        description="This easy eating system and 6-week meal plan is a complete blueprint to making food work like a team in your body so you can enjoy better energy, natural fat burning and support your active lifestyle."
                        isHome={false}
                        link="/products/body-fuel-system"
                        categoryLink="/category/meal-plans"
                        category="meal plans"
                    />

                    {/*banner Body Fuel System version 2
                    <BannerItem
                        modifierClass="bg-banner-bfs2"
                        columns="col-12 col-md-7 col-lg-6"
                        imgSrc="/images/banners/bfs2-mobile.png"
                        imgWidth="627"
                        imgHeight="328"
                        title="<span class='txt--pink'>Body Fuel</span> System"
                        description="This easy eating system and 6-week meal plan is a complete blueprint to making food work like a team in your body so you can enjoy better energy, natural fat burning and support your active lifestyle."
                        isHome={false}
                        link="/products/body-fuel-system"
                        categoryLink="/category/meal-plans"
                        category="meal plans"
                    />*/}

                    {/*banner Body Fuel System version 3
                    <BannerItem
                        modifierClass="bg-banner-bfs3"
                        columns="col-12 col-md-7 col-lg-6"
                        imgSrc=""
                        imgWidth="767"
                        imgHeight="430"
                        title="<span class='txt--pink'>Body Fuel</span> System"
                        description="This easy eating system and 6-week meal plan is a complete blueprint to making food work like a team in your body so you can enjoy better energy, natural fat burning and support your active lifestyle."
                        isHome={false}
                        link="/products/body-fuel-system"
                        categoryLink="/category/meal-plans"
                        category="meal plans"
                    />*/}

                    {/*banner 30 day challenge*/}
                    <BannerItem
                        modifierClass="bg-banner-30dch"
                        columns="col-12 col-md-7 col-lg-6"
                        imgSrc=""
                        imgWidth="716"
                        imgHeight="400"
                        title="<span class='txt--pink'>30-day</span> challenge <span class='d-inline-block'>meal plan</span>"
                        description="Enjoy tasty, delicious meals designed to support your body from the inside out with healthy recipes and done for you meal plans!"
                        isHome={false}
                        link="/products/30-day-meal-plan"
                        categoryLink="/category/meal-plans"
                        category="meal plans"
                    />

                    {/*banner 30 day challenge version2
                    <BannerItem
                        modifierClass="bg-banner-30dch2"
                        columns="col-12 col-md-7 col-lg-6"
                        imgSrc="/images/banners/30dch-mobile.png"
                        imgWidth="767"
                        imgHeight="513"
                        title="<span class='txt--pink'>30-day</span> challenge <span class='d-inline-block'>meal plan</span>"
                        description="Enjoy tasty, delicious meals designed to support your body from the inside out with healthy recipes and done for you meal plans!"
                        isHome={false}
                        link="/products/30-day-meal-plan"
                        categoryLink="/category/meal-plans"
                        category="meal plans"
                    />*/}

                    {/*banner 30 day challenge version3
                    <BannerItem
                        modifierClass="bg-banner-30dch3"
                        columns="col-12 col-md-7 col-lg-6"
                        imgSrc=""
                        imgWidth="767"
                        imgHeight="430"
                        title="<span class='txt--pink'>30-day</span> challenge <span class='d-inline-block'>meal plan</span>"
                        description="Enjoy tasty, delicious meals designed to support your body from the inside out with healthy recipes and done for you meal plans!"
                        isHome={false}
                        link="/products/30-day-meal-plan"
                        categoryLink="/category/meal-plans"
                        category="meal plans"
                    />*/}

                    {/*banner 7 day healthy*/}
                    <BannerItem
                        modifierClass="bg-banner-7day"
                        columns="col-12 col-md-7 col-lg-6"
                        imgSrc=""
                        imgWidth="767"
                        imgHeight="380"
                        title="<span class='txt--pink'>7-day</span> healthy eating meal plan"
                        description="Try a week of healthy time-saving delicious recipes that will burn fat and support your active lifestyle!"
                        isHome={false}
                        link="/products/7-day-meal-plan"
                        categoryLink="/category/meal-plans"
                        category="meal plans"
                    />

                    {/*banner 7 day healthy version2
                    <BannerItem
                        modifierClass="bg-banner-7day2"
                        columns="col-12 col-md-7 col-lg-6"
                        imgSrc="/images/banners/7day-mobile.png"
                        imgWidth="767"
                        imgHeight="513"
                        title="<span class='txt--pink'>7-day</span> healthy eating meal plan"
                        description="Try a week of healthy time-saving delicious recipes that will burn fat and support your active lifestyle!"
                        isHome={false}
                        link="/products/7-day-meal-plan"
                        categoryLink="/category/meal-plans"
                        category="meal plans"
                    />*/}

                    {/*banner 7 day healthy version3
                    <BannerItem
                        modifierClass="bg-banner-7day3"
                        columns="col-12 col-md-7 col-lg-6"
                        imgSrc=""
                        imgWidth="767"
                        imgHeight="500"
                        title="<span class='txt--pink'>7-day</span> healthy eating meal plan"
                        description="Try a week of healthy time-saving delicious recipes that will burn fat and support your active lifestyle!"
                        isHome={false}
                        link="/products/7-day-meal-plan"
                        categoryLink="/category/meal-plans"
                        category="meal plans"
                    />*/}
                </Slider>
            }

            {/*WORKOUT PLANS*/}
            { type === 'workout plans' &&
                <Slider
                    showIndicators={true}
                    autoPlay={true}
                    notSwipeable={false}
                    showThumbs={false}>

                    {/*banner 90 day home workout*/}
                    <BannerItem
                        modifierClass="bg-banner-90dch"
                        columns="col-12 col-md-8 col-lg-6 offset-lg-6 offset-md-4"
                        imgSrc=""
                        imgWidth="767"
                        imgHeight="400"
                        title="<span class='txt--pink'>90-day</span> home<br class='d-none d-lg-block'/> <span class='d-inline-block'>workout <span class='txt--pink'>challenge</span></span>"
                        description="#makefatcry in 15 minutes a Day! No Equipment, No Excuses, Just #stopdropandbettyrock"
                        isHome={false}
                        link="/products/90-day-challenge/"
                        categoryLink="/category/workout-plans/"
                        category="workout plans"
                    />

                    {/*banner 90 day home workout version2
                    <BannerItem
                        modifierClass="bg-banner-90dch2"
                        columns="col-12 col-md-8 col-lg-6"
                        imgSrc="/images/banners/90dch2-mobile.png"
                        imgWidth="676"
                        imgHeight="379"
                        title="<span class='txt--pink'>90-day</span> home<br class='d-none d-lg-block'/> workout challenge"
                        description="#makefatcry in 15 minutes a Day! No Equipment, No Excuses, Just #stopdropandbettyrock"
                        isHome={false}
                        link="/products/90-day-challenge/"
                        categoryLink="/category/workout-plans/"
                        category="workout plans"
                    />*/}

                    {/*banner 90 day home workout version3
                    <BannerItem
                        modifierClass="bg-banner-90dch3"
                        columns="col-12 col-md-8 col-xl-6"
                        imgSrc=""
                        imgWidth="761"
                        imgHeight="400"
                        title="<span class='txt--pink'>90-day</span> home<br class='d-none d-lg-block'/> workout <span>challenge</span>"
                        description="#makefatcry in 15 minutes a Day! No Equipment, No Excuses, Just #stopdropandbettyrock"
                        isHome={false}
                        link="/products/90-day-challenge/"
                        categoryLink="/category/workout-plans/"
                        category="workout plans"
                    />*/}

                    {/*banner 90 day home workout version3-1
                    <BannerItem
                        modifierClass="bg-banner-90dch3-1"
                        columns="col-12 col-md-8 col-lg-6"
                        imgSrc=""
                        imgWidth="767"
                        imgHeight="450"
                        title="<span class='txt--pink'>90-day</span> home<br class='d-none d-lg-block'/> workout challenge"
                        description="#makefatcry in 15 minutes a Day! No Equipment, No Excuses, Just #stopdropandbettyrock"
                        isHome={false}
                        link="/products/90-day-challenge/"
                        categoryLink="/category/workout-plans/"
                        category="workout plans"
                    />*/}

                    {/*banner 90 day home workout version3-2
                    <BannerItem
                        modifierClass="bg-banner-90dch3-2"
                        columns="col-12 col-md-8 col-lg-6"
                        imgSrc=""
                        imgWidth="767"
                        imgHeight="450"
                        title="<span class='txt--pink'>90-day</span> home<br class='d-none d-lg-block'/> workout challenge"
                        description="#makefatcry in 15 minutes a Day! No Equipment, No Excuses, Just #stopdropandbettyrock"
                        isHome={false}
                        link="/products/90-day-challenge/"
                        categoryLink="/category/workout-plans/"
                        category="workout plans"
                    />*/}

                    {/*banner hwd*/}
                    <BannerItem
                        modifierClass="bg-banner-hwd"
                        columns="col-12 col-md-8 col-lg-6 offset-lg-6 offset-md-4"
                        imgSrc=""
                        imgWidth="767"
                        imgHeight="400"
                        title="<span class='txt--pink'>Home</span><br class='d-none d-lg-block'/> workout domination"
                        description="A time-saving workout plan to do at home, at the gym, or on the road – this plan will tone and tighten your body!"
                        isHome={false}
                        link="/products/home-workout-domination/"
                        categoryLink="/category/workout-plans/"
                        category="workout plans"
                    />

                    {/*banner hwd version2
                    <BannerItem
                        modifierClass="bg-banner-hwd2"
                        columns="col-12 col-md-8 col-lg-6"
                        imgSrc="/images/banners/hwd2-mobile.png"
                        imgWidth="663"
                        imgHeight="341"
                        title="<span class='txt--pink'>Home</span><br class='d-none d-lg-block'/> workout domination"
                        description="A time-saving workout plan to do at home, at the gym, or on the road – this plan will tone and tighten your body!"
                        isHome={false}
                        link="/products/home-workout-domination/"
                        categoryLink="/category/workout-plans/"
                        category="workout plans"
                    />*/}

                    {/*banner hwd version3
                    <BannerItem
                        modifierClass="bg-banner-hwd3"
                        columns="col-12 col-md-8 col-lg-6 offset-lg-6 offset-md-4"
                        imgSrc=""
                        imgWidth="767"
                        imgHeight="470"
                        title="<span class='txt--pink'>Home</span><br class='d-none d-lg-block'/> workout domination"
                        description="A time-saving workout plan to do at home, at the gym, or on the road – this plan will tone and tighten your body!"
                        isHome={false}
                        link="/products/home-workout-domination/"
                        categoryLink="/category/workout-plans/"
                        category="workout plans"
                    />*/}

                    {/*banner booty + abs 30-day challenge program
                    <BannerItem
                        modifierClass="bg-banner-booty"
                        columns="col-12 col-md-8 col-xl-6 offset-xl-6 offset-md-4"
                        imgSrc=""
                        imgWidth="767"
                        imgHeight="480"
                        title="<span class='txt--pink'>Booty + abs</span><br class='d-none d-sm-block'/> 30-day challenge program"
                        description="Define your abs and sculpt your booty and legs with this time-saving 30-day workout, at home or in the gym!"
                        isHome={false}
                        link="/products/30-day-booty-abs-challenge/"
                        categoryLink="/category/workout-plans/"
                        category="workout plans"
                    />*/}

                    {/*banner booty2*/}
                    <BannerItem
                        modifierClass="bg-banner-booty2"
                        columns="col-12 col-md-8 col-lg-6 offset-lg-6 offset-md-4"
                        imgSrc=""
                        imgWidth="767"
                        imgHeight="400"
                        title="<span class='txt--pink'>Booty + abs</span><br class='d-none d-sm-block'/> 30-day challenge program"
                        description="Define your abs and sculpt your booty and legs with this time-saving 30-day workout, at home or in the gym!"
                        isHome={false}
                        link="/products/30-day-booty-abs-challenge/"
                        categoryLink="/category/workout-plans/"
                        category="workout plans"
                    />

                    {/*banner booty3
                    <BannerItem
                        modifierClass="bg-banner-booty3"
                        columns="col-12 col-md-8 col-lg-6"
                        imgSrc="/images/banners/booty3-mobile.png"
                        imgWidth="670"
                        imgHeight="381"
                        title="<span class='txt--pink'>Booty + abs</span><br class='d-none d-sm-block'/> 30-day challenge program"
                        description="Define your abs and sculpt your booty and legs with this time-saving 30-day workout, at home or in the gym!"
                        isHome={false}
                        link="/products/30-day-booty-abs-challenge/"
                        categoryLink="/category/workout-plans/"
                        category="workout plans"
                    />*/}

                    {/*banner lioness*/}
                    <BannerItem
                        modifierClass="bg-banner-lioness"
                        columns="col-12 col-md-8 col-lg-6 offset-lg-6 offset-md-4"
                        imgSrc=""
                        imgWidth="767"
                        imgHeight="400"
                        title="<span class='txt--pink'>Lioness</span>"
                        description="Unleash your power with progressive strength workouts designed to sculpt lean muscle and build a fit physique!"
                        isHome={false}
                        link="/products/lioness/"
                        categoryLink="/category/workout-plans/"
                        category="workout plans"
                    />

                    {/*banner lioness2
                    <BannerItem
                        modifierClass="bg-banner-lioness2"
                        columns="col-12 col-md-8 col-lg-6"
                        imgSrc="/images/banners/lioness2-mobile.png"
                        imgWidth="666"
                        imgHeight="394"
                        title="<span class='txt--pink'>Lioness</span>"
                        description="Unleash your power with progressive strength workouts designed to sculpt lean muscle and build a fit physique!"
                        isHome={false}
                        link="/products/lioness/"
                        categoryLink="/category/workout-plans/"
                        category="workout plans"
                    />*/}

                    {/*banner lioness3
                    <BannerItem
                        modifierClass="bg-banner-lioness3"
                        columns="col-12 col-md-8 col-lg-6 offset-lg-6 offset-md-4"
                        imgSrc=""
                        imgWidth="767"
                        imgHeight="500"
                        title="<span class='txt--pink'>Lioness</span>"
                        description="Unleash your power with progressive strength workouts designed to sculpt lean muscle and build a fit physique!"
                        isHome={false}
                        link="/products/lioness/"
                        categoryLink="/category/workout-plans/"
                        category="workout plans"
                    />*/}
                </Slider>
            }

            {/*SUPPLEMENTS*/}
            { type === 'supplements' &&
                <Slider
                    showIndicators={true}
                    autoPlay={true}
                    notSwipeable={false}
                    showThumbs={false}>
                    {/*banner Berry Green Protein*/}
                    <BannerItem
                        modifierClass="bg-banner-bgp"
                        columns="col-12 col-md-8 col-lg-6"
                        imgSrc=""
                        imgWidth="767"
                        imgHeight="380"
                        title="Berry Green Protein"
                        description="100% organic, high protein (18g protein per serving), nutrient-dense whole food powder combining 15 superfood greens and berries into one delicious smooth strawberry vanilla flavored shake!"
                        isHome={false}
                        link="/products/berry-green-protein/"
                        categoryLink="/category/supplements/"
                        category="supplements"
                    />

                    {/*banner Berry Green Protein version 2
                    <BannerItem
                        modifierClass="bg-banner-bgp2"
                        columns="col-12 col-md-8 col-lg-6"
                        imgSrc=""
                        imgWidth="767"
                        imgHeight="450"
                        title="Berry Green Protein"
                        description="100% organic, high protein (18g protein per serving), nutrient-dense whole food powder combining 15 superfood greens and berries into one delicious smooth strawberry vanilla flavored shake!"
                        isHome={false}
                        link="/products/berry-green-protein/"
                        categoryLink="/category/supplements/"
                        category="supplements"
                    />*/}

                    {/*banner Full body collagen
                    <BannerItem
                        modifierClass="bg-banner-fbc"
                        columns="col-12 col-md-8 col-lg-6"
                        imgSrc=""
                        imgWidth="767"
                        imgHeight="600"
                        title="Full body collagen"
                        description="Easily dissolvable 30 servings of Hydrolyzed Collagen Peptides for daily bone, joint and skin support."
                        isHome={false}
                        link="/products/full-body-collagen/"
                        categoryLink="/category/supplements/"
                        category="supplements"
                    />*/}

                    {/*banner Full body collagen 2*/}
                    <BannerItem
                        modifierClass="bg-banner-fbc2"
                        columns="col-12 col-md-8 col-lg-6"
                        imgSrc=""
                        imgWidth="767"
                        imgHeight="440"
                        title="Full body collagen"
                        description="Easily dissolvable 30 servings of Hydrolyzed Collagen Peptides for daily bone, joint and skin support."
                        isHome={false}
                        link="/products/full-body-collagen/"
                        categoryLink="/category/supplements/"
                        category="supplements"
                    />

                    {/*banner Rock and Restore
                    <BannerItem
                        modifierClass="bg-banner-rr"
                        columns="col-12 col-md-8 col-lg-6"
                        imgSrc=""
                        imgWidth="767"
                        imgHeight="400"
                        title="Rock and restore"
                        description="This great tasting fruit punch formula contains all 9 of the essential amino acids (including the BCAA’s) so you can rock your workouts, build lean muscle, recover faster, boost your immune system and improve cognitive function."
                        isHome={false}
                        link="/products/rock-and-restore/"
                        categoryLink="/category/supplements/"
                        category="supplements"
                    />*/}

                    {/*banner Rock and Restore 2*/}
                    <BannerItem
                        modifierClass="bg-banner-rr2"
                        rockAndRestore={true}
                        columns="col-12 col-md-8 col-lg-6"
                        imgSrc=""
                        imgWidth="767"
                        imgHeight="340"
                        title="Rock and restore"
                        description="This great tasting fruit punch formula contains all 9 of the essential amino acids (including the BCAA’s) so you can rock your workouts, build lean muscle, recover faster, boost your immune system and improve cognitive function."
                        isHome={false}
                        link="/products/rock-and-restore/"
                        categoryLink="/category/supplements/"
                        category="supplements"
                    />

                    {/*banner Vanilla Protein
                    <BannerItem
                        modifierClass="bg-banner-vp"
                        columns="col-12 col-md-8 col-lg-6"
                        imgSrc=""
                        imgWidth="767"
                        imgHeight="500"
                        title="I <i class='fas fa-heart txt--pink'></i> Vanilla Protein<sup>&trade;</sup>"
                        description="100% organic, high protein (20g protein per serving), nutrient-dense whole food protein powder combining 4 plant-based protein sources into a delicious vanilla shake to support an active lifestyle!"
                        isHome={false}
                        link="/products/vanilla-protein/"
                        categoryLink="/category/supplements/"
                        category="supplements"
                    />*/}

                    {/*banner Vanilla Protein version 2*/}
                    <BannerItem
                        modifierClass="bg-banner-vp2"
                        columns="col-12 col-md-8 col-lg-6"
                        imgSrc=""
                        imgWidth="767"
                        imgHeight="380"
                        title="I <i class='fas fa-heart txt--pink'></i> Vanilla Protein<sup>&trade;</sup>"
                        description="100% organic, high protein (20g protein per serving), nutrient-dense whole food protein powder combining 4 plant-based protein sources into a delicious vanilla shake to support an active lifestyle!"
                        isHome={false}
                        link="/products/vanilla-protein/"
                        categoryLink="/category/supplements/"
                        category="supplements"
                    />

                    {/*banner Vanilla Protein version3
                    <BannerItem
                        modifierClass="bg-banner-vp3"
                        columns="col-12 col-md-8 col-lg-6"
                        imgSrc=""
                        imgWidth="767"
                        imgHeight="490"
                        title="I <i class='fas fa-heart txt--pink'></i> Vanilla Protein<sup>&trade;</sup>"
                        description="100% organic, high protein (20g protein per serving), nutrient-dense whole food protein powder combining 4 plant-based protein sources into a delicious vanilla shake to support an active lifestyle!"
                        isHome={false}
                        link="/products/vanilla-protein/"
                        categoryLink="/category/supplements/"
                        category="supplements"
                    />*/}

                    {/*banner SereniCalm*/}
                    <BannerItem
                        modifierClass="bg-banner-sc"
                        columns="col-12 col-md-8 col-lg-6"
                        imgSrc=""
                        imgWidth="767"
                        imgHeight="440"
                        title="SereniCalm"
                        description="An all-natural, non-habit forming stress relief supplement to bring more serenity and calm to your body and mind."
                        isHome={false}
                        link="/products/serenicalm/"
                        categoryLink="/category/supplements/"
                        category="supplements"
                    />

                    {/*banner SereniCalm version2
                    <BannerItem
                        modifierClass="bg-banner-sc2"
                        columns="col-12 col-md-8 col-lg-6"
                        imgSrc=""
                        imgWidth="767"
                        imgHeight="600"
                        title="SereniCalm"
                        description="An all-natural, non-habit forming stress relief supplement to bring more serenity and calm to your body and mind."
                        isHome={false}
                        link="/products/serenicalm/"
                        categoryLink="/category/supplements/"
                        category="supplements"
                    />*/}

                    {/*banner Whole Sleep*/}
                    <BannerItem
                        modifierClass="bg-banner-ws"
                        columns="col-12 col-md-8 col-lg-6"
                        imgSrc=""
                        imgWidth="767"
                        imgHeight="400"
                        title="Whole sleep"
                        description="A blend of herbs and organic compounds that support your body’s natural sleep cycle to help you fall asleep and stay asleep - so you wake up refreshed and energized, and ready to ROCK your day!"
                        isHome={false}
                        link="/products/whole-sleep/"
                        categoryLink="/category/supplements/"
                        category="supplements"
                    />

                    {/*banner Whole Sleep version2
                    <BannerItem
                        modifierClass="bg-banner-ws2"
                        columns="col-12 col-md-8 col-lg-6"
                        imgSrc=""
                        imgWidth="767"
                        imgHeight="450"
                        title="Whole sleep"
                        description="A blend of herbs and organic compounds that support your body’s natural sleep cycle to help you fall asleep and stay asleep - so you wake up refreshed and energized, and ready to ROCK your day!"
                        isHome={false}
                        link="/products/whole-sleep/"
                        categoryLink="/category/supplements/"
                        category="supplements"
                    />*/}
                </Slider>
            }

            {/*DIGITAL PRODUCTS*/}
            { type === 'products' &&
                <Slider
                    showIndicators={true}
                    autoPlay={true}
                    notSwipeable={false}
                    showThumbs={false}>

                    {/*banner 90 day home workout*/}
                    <BannerItem
                        modifierClass="bg-banner-90dch"
                        columns="col-12 col-md-8 col-lg-6 offset-lg-6 offset-md-4"
                        imgSrc=""
                        imgWidth="767"
                        imgHeight="400"
                        title="<span class='txt--pink'>90-day</span> home<br class='d-none d-lg-block'/> <span class='d-inline-block'>workout <span class='txt--pink'>challenge</span></span>"
                        description="#makefatcry in 15 minutes a Day! No Equipment, No Excuses, Just #stopdropandbettyrock"
                        isHome={false}
                        link="/products/90-day-challenge/"
                        categoryLink="/category/workout-plans/"
                        category="workout plans"
                    />

                    {/*banner hwd*/}
                    <BannerItem
                        modifierClass="bg-banner-hwd"
                        columns="col-12 col-md-8 col-lg-6 offset-lg-6 offset-md-4"
                        imgSrc=""
                        imgWidth="767"
                        imgHeight="400"
                        title="<span class='txt--pink'>Home</span><br class='d-none d-lg-block'/> workout domination"
                        description="A time-saving workout plan to do at home, at the gym, or on the road – this plan will tone and tighten your body!"
                        isHome={false}
                        link="/products/home-workout-domination/"
                        categoryLink="/category/workout-plans/"
                        category="workout plans"
                    />

                    {/*banner booty2*/}
                    <BannerItem
                        modifierClass="bg-banner-booty2"
                        columns="col-12 col-md-8 col-lg-6 offset-lg-6 offset-md-4"
                        imgSrc=""
                        imgWidth="767"
                        imgHeight="400"
                        title="<span class='txt--pink'>Booty + abs</span><br class='d-none d-sm-block'/> 30-day challenge program"
                        description="Define your abs and sculpt your booty and legs with this time-saving 30-day workout, at home or in the gym!"
                        isHome={false}
                        link="/products/30-day-booty-abs-challenge/"
                        categoryLink="/category/workout-plans/"
                        category="workout plans"
                    />

                    {/*banner lioness*/}
                    <BannerItem
                        modifierClass="bg-banner-lioness"
                        columns="col-12 col-md-8 col-lg-6 offset-lg-6 offset-md-4"
                        imgSrc=""
                        imgWidth="767"
                        imgHeight="400"
                        title="<span class='txt--pink'>Lioness</span>"
                        description="Unleash your power with progressive strength workouts designed to sculpt lean muscle and build a fit physique!"
                        isHome={false}
                        link="/products/lioness/"
                        categoryLink="/category/workout-plans/"
                        category="workout plans"
                    />

                    {/*banner Body Fuel System*/}
                    <BannerItem
                        modifierClass="bg-banner-bfs"
                        columns="col-12 col-md-7 col-lg-6"
                        imgSrc=""
                        imgWidth="767"
                        imgHeight="380"
                        title="<span class='txt--pink'>Body Fuel</span> System"
                        description="This easy eating system and 6-week meal plan is a complete blueprint to making food work like a team in your body so you can enjoy better energy, natural fat burning and support your active lifestyle."
                        isHome={false}
                        link="/products/body-fuel-system"
                        categoryLink="/category/meal-plans"
                        category="meal plans"
                    />

                    {/*banner 30 day challenge*/}
                    <BannerItem
                        modifierClass="bg-banner-30dch"
                        columns="col-12 col-md-7 col-lg-6"
                        imgSrc=""
                        imgWidth="716"
                        imgHeight="400"
                        title="<span class='txt--pink'>30-day</span> challenge <span class='d-inline-block'>meal plan</span>"
                        description="Enjoy tasty, delicious meals designed to support your body from the inside out with healthy recipes and done for you meal plans!"
                        isHome={false}
                        link="/products/30-day-meal-plan"
                        categoryLink="/category/meal-plans"
                        category="meal plans"
                    />

                    {/*banner 7 day healthy*/}
                    <BannerItem
                        modifierClass="bg-banner-7day"
                        columns="col-12 col-md-7 col-lg-6"
                        imgSrc=""
                        imgWidth="767"
                        imgHeight="380"
                        title="<span class='txt--pink'>7-day</span> healthy eating meal plan"
                        description="Try a week of healthy time-saving delicious recipes that will burn fat and support your active lifestyle!"
                        isHome={false}
                        link="/products/7-day-meal-plan"
                        categoryLink="/category/meal-plans"
                        category="meal plans"
                    />
                </Slider>
            }

        </>
    )
}
export default Banners;
