import productData from "../../static/data/product";
import ProductBox from "./ProductBox";
import React, {useEffect, useState} from "react";

const ProductBoxContainer = ({productId, variantId, type, item, handlePopup, handleProduct, handleShopify}) => {

    const [shopifyVariantId, setShopifyVariantId] = useState('')
    useEffect(()=>{
        if(variantId.indexOf("Shopify__ProductVariant__") !== -1) {
            setShopifyVariantId(variantId.split("Shopify__ProductVariant__")[1])
        }
    },[])

    return (
        <>
            {
                (shopifyVariantId === productId && productData[productId].type === type) &&
                <div className={`col-12 col-sm-6 ${ type === 'workout plan' ? 'col-lg-3' : 'col-lg-4' } margin-responsive`}>
                    <ProductBox
                        handlePopup={handlePopup}
                        handleProduct={handleProduct}
                        type={productData[productId].type}
                        item={item}
                        handleShopify={handleShopify}
                        product={productData[productId]}/>
                </div>
            }
        </>
    )
}
export default ProductBoxContainer
